/* eslint-disable no-unused-vars */
import { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { Route, Routes, Navigate } from "react-router-dom";
import Main from "./components/Main";
// import About from "./components/About";
// import Signup from "./components/Singup";
// import Login from "./components/Login";
// import Contact from "./components/Contact";
import Navbar from './components/pages/Navbar';
import Footer from './components/pages/Footer';
// import Admin from './components/Admin';

// import AdminMessage from "./components/AdminDashboard/ContactMessages";
// import UserApproval from "./components/AdminDashboard/UserApproval";
// import InternshipFrom from "./components/AdminDashboard/InternshipFrom";
// import PlacementFrom from "./components/AdminDashboard/PlacementForm";
// import NewsFrom from "./components/AdminDashboard/News";
// import UserManagement from "./components/AdminDashboard/UserManagement";
// import Magement from "./components/AdminDashboard/Magement";
// import Internships from "./components/Internships";
// import Placements from "./components/Placements";

import News from "./components/News";

function App() {
    // eslint-disable-next-line no-unused-vars
    const user = localStorage.getItem("token");
    const isAuthenticated = localStorage.getItem('token');
    const isAdmin = localStorage.getItem('isAdmin');
    const [showPaymentNotice, setShowPaymentNotice] = useState(true);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        window.location.reload();
    };

    const closePaymentNotice = () => {
        setShowPaymentNotice(false);
    };

    return (
        <div>
            <Navbar isAuthenticated={isAuthenticated} isAdmin={isAdmin} handleLogout={handleLogout} />
            
            {/* Hosting Payment Notice Modal */}
            {showPaymentNotice && (
                <div style={modalStyle}>
                    <div style={modalContentStyle}>
                        <h2 style={headerStyle}>⚠️ Your Website Is About To Loss All Data ⚠️</h2>
                        <p style={paragraphStyle}>
                            Your hosting payment for the year <strong>2023</strong> is <span style={highlightStyle}>pending</span>.  
                        </p>
                        <p style={paragraphStyle}>
                            Failure to address this matter immediately may result in <strong>data loss</strong> or <strong>service interruption</strong>.  
                        </p>
                        <p style={paragraphStyle}>
                            To avoid losing access to your website and its data, please contact 
                            <strong> Mr. Akash Mandal</strong>, the hosting server owner, at the earliest.
                        </p>
                        <button style={buttonStyle} onClick={closePaymentNotice}>Close</button>
                    </div>
                </div>
            )}

            <Routes>
                <Route path="/" exact element={<Main />} />
                {/* <Route path="/about" exact element={<About />} />
                <Route path="/signup" exact element={<Signup />} />
                <Route path="/login" exact element={<Login />} />
                <Route path="/admin" exact element={<Admin />} />

                <Route path="/admin-message" element={<AdminMessage />} />
                <Route path="/user-approval" element={<UserApproval />} />
                <Route path="/internship-from" element={<InternshipFrom />} />
                <Route path="/placement-from" element={<PlacementFrom />} />
                <Route path="/news-from" element={<NewsFrom />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/manage" element={<Magement />} />
                {user && <Route path="/internships" element={<Internships />} />}
                {user && <Route path="/placements" element={<Placements />} />}
                <Route path="/news" element={<News />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<Navigate replace to="/login" />} /> */}
            </Routes>

            <Footer />
        </div>
    );
}

// Inline Styles for Modal
const modalStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000"
};

const modalContentStyle = {
    backgroundColor: "white",
    padding: "2rem",
    borderRadius: "10px",
    textAlign: "center",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
};

const headerStyle = {
    color: "red",
    fontWeight: "bold",
    marginBottom: "1rem"
};

const paragraphStyle = {
    color: "#333",
    fontSize: "1rem",
    marginBottom: "1rem"
};

const highlightStyle = {
    color: "red",
    fontWeight: "bold"
};

const buttonStyle = {
    backgroundColor: "#FF0000",
    color: "white",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    cursor: "pointer"
};

export default App;
